import { useState, useEffect } from 'react'
import { useRoutes, Redirect, useNavigate, useFullPath } from "raviger";
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useApi from "../hooks/useApi";
import useGlobal from '../store/store';
//libs
import { Animated } from 'react-animated-css'
import { isBefore, isEqual } from 'date-fns'
// import Quack from '@thequack/sdk';
//mui
import { makeStyles } from '@mui/styles';
import { Box, Typography, Backdrop, Stack, IconButton, Input, LinearProgress, Tooltip, Button } from '@mui/material';
import * as Styled from "../Components/Common/StyledComponents";
import CloseIcon from '@mui/icons-material/Close';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Splitpaymentalert from '../assets/Splitpaymentalert.svg';
import childError from '../assets/childError.svg';
import approvalPay from '../assets/approvalPay.png'
//api
import { parentChildrenAPI, getRegAPI, removeRegAPI, addRefundAPI, UpdateRegAPI, getRefundsToFamily, findChildInDeepLink, getSplitLink } from "../api/api";
//components
import { Cart } from '../Components/Cart/Cart';
import { ParentsSettings } from 'Components/ParentComponents/MyFamilyComponents/ParentsSetting';
import { MyFamily } from '../pages/MyFamily';
import { datePast } from 'Components/TribeComponents/ActivityItem';
import { addActivitiesToCart } from 'Components/ParentComponents/ParentCartButton';
import { MessageBox } from 'Components/Common/MessageBox';
import { TribeIsUpdatedContext } from "context/tribeIsUpdateContext";
import { AddChildren } from 'Components/Register/AddChildren';
import { RegisterContext } from 'context/registerContext';
import { ChildDetailsEditor } from 'Components/ParentComponents/MyFamilyComponents/ChildDetailsEditor';
import { GreenButton } from 'Components/Register/AddAnotherParent';
import ParentContact from './ParentContact';
import ParentHomePage from './ParentHomePage';
import ParentAppBar from '../Components/ParentComponents/ParentAppBar';
import ZofimLoader from 'Components/Common/ZofimLoader';
import ParentRegsHist from './parentHistoryPages/ParentRegsHist';
import GenericDialog from 'Components/Common/GenericDIalog';
import ParentRefundsPage from './parentHistoryPages/ParentRefundsPage';

const useStyles = makeStyles(() => ({
    parentBackground: {
        textAlign: "-webkit-center !important",
    },
    pagesContainer: {
        position: "relative"
    },
    backgroundLink: {
        backgroundColor: '#e6efe6 !important',
        border: '1px solid #3a7735 !important',
        height: '2.75rem !important',
        width: '80% !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        borderRadius: '10px !important'
    },
    link: {
        '&.MuiInputBase-root.Mui-disabled': {
            color: 'black !important',
            '&.MuiInput-underline.Mui-disabled:before': {
                borderBottomStyle: "none !important"
            }
        },
        fontWeight: 'bold !important'
    }
}));

const Parent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();
    const urlParams = useFullPath();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [parentTab, setParentTab] = useState(1);
    const [isUpdated, setIsUpdated] = useState(0);
    const [tribeUpdate, setUpdated] = useState(0);
    const [addedLength, setAddedLength] = useState(0);
    const [appBarTitle, setAppBarTitle] = useState("");
    const [splitLink, setSplitLink] = useState("");
    const [familyTribes, setFamilyTribes] = useState([]);
    const [kibbutzUser, setKibbutzUser] = useState(false);
    const [ready, setReady] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false);
    const [openSplitDialog, setOpenSplitDialog] = useState(false);
    const [moveToHistory, setMoveToHistory] = useState(false);
    const [openActAdded, setOpenActAdded] = useState(false);
    const [updateParent, setUpdateParent] = useState({});
    const [openSummerNote, setOpenSummerNote] = useState(false);

    const [messageBoxState, setMessageBox] = useState({ open: false });
    const findDeepLinkPupil = useApi(findChildInDeepLink);
    const getSplitLinkApi = useApi(getSplitLink);
    const getFamilyRefunds = useApi(getRefundsToFamily);
    const tribeUpdatedContext = { tribeUpdate, setUpdated };

    const [user] = useGlobal((state) => state.user);
    const [household, [setHousehold, removeHouseholdSelection]] = useGlobal((state) => state.household, (actions) => [actions.setHousehold, actions.removeHouseholdSelection]);
    const [householdSelections, [setHouseholdSelections, addHouseholdSelection]] = useGlobal((state) => state.householdSelections, (actions) => [actions.setHouseholdSelections, actions.addHouseholdSelection]);
    const [cartFamily, setCartFamily] = useGlobal((state) => state.cartFamily, (actions) => actions.setCartFamily);
    const [cartState, setCartState] = useGlobal((state) => state.cartState, (actions) => actions.setCartState);
    const [products, [setProducts, addProduct]] = useGlobal((state) => state.products, (actions) => [actions.setProducts, actions.addProduct]);
    const [deepLink, setDeepLink] = useGlobal((state) => state.deepLink, (actions) => actions.setDeepLink);
    const [parentRegs, setParentRegs] = useGlobal((state) => state.parentRegs, (actions) => actions.setParentRegs);

    const parentRoutes =
    {
        '/contact': () => <ParentContact />,
        '/homepage': () => <ParentHomePage isUpdated={isUpdated} setIsUpdated={setIsUpdated} setKibbutzUser={setKibbutzUser} navigate={navigate} setOpenSummerNote={setOpenSummerNote} setAppBarTitle={setAppBarTitle} />,
        '/MyFamily': () => <MyFamily household={household} familyTribes={familyTribes} setParentTab={setParentTab} setAppBarTitle={setAppBarTitle} />,
        '/reghist': () => <ParentRegsHist removeHouseholdSelection={removeHouseholdSelection} isUpdated={isUpdated} setIsUpdated={setIsUpdated} setAppBarTitle={setAppBarTitle} />,
        '/regHistRefunds': () => <ParentRefundsPage setAppBarTitle={setAppBarTitle} />,
        '/parentUserSettings': () => <ParentsSettings setIsUpdated={setIsUpdated} isUpdated={isUpdated} parentTab={parentTab} setParentTab={setParentTab} fam={household} />,
        '/addChild': () => <RegisterContext.Provider value={setUpdateParent}><AddChildren cardCode={household.CardCode} myFamily={true} setIsUpdated={setIsUpdated} isUpdated={isUpdated} lastName={household.Parent1_LastName} /></RegisterContext.Provider>,
        '/childDetailsEditor': () => <ChildDetailsEditor setIsUpdated={setIsUpdated} isUpdated={isUpdated} setAppBarTitle={setAppBarTitle} />,
        '*': () => <Redirect to='/parent/homepage' />
    }

    const protectedRoutes = useRoutes(parentRoutes, { basePath: "/parent" });

    const prepareActivitesForCart = (currentHousehold) => {
        const selectedActivities = [];
        currentHousehold?.ChildrenList?.results?.forEach(child => {
            child.ChildFactoriesPortal.results.forEach(activity => {
                if (activity._id && (activity.Status_request === "1")) {
                    selectedActivities.push(activity);
                }
            })

        });
        setProducts(selectedActivities);
        setCartFamily(currentHousehold.CardCode);
        return (selectedActivities)
    }

    const futureRegistration = (regDate) => {
        try {
            const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
            const today = new Date();
            const regDate1 = new Date(Number(reMsAjax.exec(regDate)[1]));
            return today < regDate1;
        } catch (error) {
            return false;
        }
    }

    const pastRegistration = (someDate) => {
        try {
            const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
            const today = new Date();
            const endDate = new Date(Number(reMsAjax.exec(someDate)[1]));
            return today > endDate;
        } catch (error) {
            return false;
        }
    }

    const checkCorrectDates = (activity) => {
        if (!activity.EndDate || !activity.EndDateD || !activity.EndReg || !activity.EndRegDate || !activity.StartDate || !activity.StartDateD || !activity.StartReg || !activity.StartRegDate) {
            return false;
        }
        else {
            return activity;
        }
    }

    const getParentData = async () => {
        let familyCell;
        try {
            // get family cell from SB1
            const response = await parentChildrenAPI();
            familyCell = response.d?.results[0];
            familyCell.ChildrenList?.results?.forEach(child => {
                child.ChildFactoriesPortal.results = child.ChildFactoriesPortal.results.filter(a => { if (a.ActivCode && !futureRegistration(a.StartReg) && !pastRegistration(a.EndDate) && checkCorrectDates(a)) return a }); //ignore junk and future registration activities
            });
            // get registration requests from mongodb
            try {
                const registrations = await getRegAPI(); //retrieve saved registration requests and merge them with possible registrations
                let regList = registrations.data;
                const splitRegs = regList.filter((reg) => reg.Status_request === "3");
                const tribesToBlock = splitRegs.map((reg) => reg.TribeCode);
                if (splitRegs.length) {
                    const linksData = await getSplitLinkApi.requestPromise(familyCell.CardCode);
                    if (linksData.length && urlParams === "/parent/homepage") {
                        if (linksData.length === 1) {
                            setSplitLink(linksData[0].paymentLink);
                        }
                        else {
                            setMoveToHistory(true);
                        }
                        setOpenSplitDialog(true);
                    }
                }
                try {
                    const refundsResult = await getFamilyRefunds.requestPromise({ ParentCardCode: familyCell.CardCode });
                    console.log(refundsResult)
                } catch (error) {
                    console.log(error)
                }

                for (let i = 0; i < regList.length; i++) { //check saved registrations
                    let child = familyCell.ChildrenList.results.find(child => { return child.CardCode === regList[i].StudentCardCode }); //find child of saved registration
                    if (!child) { //child from registration request not found in current household
                        regList.splice(i, 1);
                        i--;
                        continue;
                    }
                    let activities = child.ChildFactoriesPortal.results;
                    let j = activities.findIndex(a => { return a.ActivCode === regList[i].ActivCode }); //check if saved registration exists in current possible registrations list
                    if (j > -1) { //saved registration exists in current possible registrations list
                        let { EndRegDate, InvoiceNum, DiscPrice, PeriodCode } = activities.find(a => a.ActivCode === regList[i].ActivCode);

                        if (DiscPrice !== regList[i].DiscPrice) {
                            await UpdateRegAPI({ _id: regList[i]._id, data: { DiscPrice } });
                        }
                        if (InvoiceNum !== regList[i].InvoiceNum) {
                            await UpdateRegAPI({ _id: regList[i]._id, data: { InvoiceNum } });
                        }
                        if (!(isEqual(new Date(regList[i].EndRegDate), new Date(EndRegDate))) && regList[i].Status_request !== "0") {//if Zofim updtae the end reg date on SAP to future ,we update the date in mongo
                            await UpdateRegAPI({ _id: regList[i]._id, data: { EndRegDate } });
                        }
                        if (tribesToBlock.find(TribeCode => TribeCode === regList[i].TribeCode)) {
                            regList[i].disabled = true;
                        }
                        // if (regList[i].Status_request === "4" && regList[i].PeriodCode === PeriodCode) {//registration request was paid before but retrieved again from SB1 = it was refunded !
                        //     await addRefundAPI(regList[i]); //save it in 'refunds' collection in mongodb
                        //     await removeRegAPI(regList[i]); //remove it from 'RegistrationRequests' collection in mongodb
                        //     regList.splice(i, 1);
                        //     i--;
                        //     continue;
                        // }
                        else if (regList[i].Status_request === "0") {//registration request was in Cart but reg date expired 
                            if (isBefore(new Date(regList[i].EndRegDate), new Date(EndRegDate))) {//zofim updtae the end reg date on SAP to future we delete from mongo
                                await removeRegAPI(regList[i]); //remove it from 'RegistrationRequests' collection in mongodb
                                regList.splice(i, 1);
                                i--;
                            }
                            continue;
                        }
                        else { //in registration process
                            activities[j] = { ...regList[i], ...activities[j] }; //merge from mongo and sap
                        }
                    }
                    else { //saved registration NOT exists in current possible registrations list
                        regList[i].disabled = true;
                        child.ChildFactoriesPortal.results.push(regList[i]); //add it to list and show user that it is no longer possible to register to that activity
                    }
                }

                setParentRegs(regList);
            } catch (error) {
                console.error(error);
            }
            /**
             * get unpaid annual fee activities,
             * set all rest of activities for current year to be disabled for purchase if annual was not selected
             */
            familyCell.ChildrenList.results.forEach(child => {
                let unpaidAnnuals = child.ChildFactoriesPortal.results.filter(u => { return (u.ItemPurposeType === "2" && !u._id) || (u.ItemPurposeType === "2" && u.Status_request !== "4") }); //get unpaid annual fee activities
                unpaidAnnuals.forEach(unpaidAnnual => {
                    child.ChildFactoriesPortal.results.forEach(activity => {
                        if (unpaidAnnual.ActivCode !== activity.ActivCode && unpaidAnnual.Class === activity.Class) {
                            activity.disabled = true;
                        }
                    })
                });
            });
            return familyCell;
        } catch (error) {
            throw new Error(error);
        }
    }

    const parentChildren = useApi(getParentData);



    useEffect(async () => {
        let oHousehold;
        setReady(false);
        setProducts([]);
        try {
            oHousehold = await parentChildren.requestPromise();
        } catch (error) {
            oHousehold = {};
            console.log(error);
        }
        setHousehold(oHousehold);
        const selectedActivities = prepareActivitesForCart(oHousehold);
        checkDeepLink(oHousehold, selectedActivities);
        const tmpTribes = {};
        oHousehold.ChildrenList?.results.map((child) => {
            tmpTribes[child.BPLName.slice(5)] = true;
        });
        setFamilyTribes(Object.keys(tmpTribes));
        setReady(true);
        // //quack function for parent contact & identify
        // try {
        //     const childrenDetails = oHousehold?.ChildrenList?.results;
        //     Quack.identify({
        //         parentName: `${user.firstName} ${user.lastName}`,
        //         childrenDetails,
        //         email: user.email,
        //         phoneNumber: user.phone_number,
        //         CardCode: oHousehold.CardCode,
        //         name: oHousehold.CardName,
        //         tribe: oHousehold.GroupName,
        //         Parent1_ID: oHousehold.Parent1_ID,
        //     })
        //     Quack.setCustom("onSubmit", submitForm);
        // } catch (error) {
        //     console.log(error);
        // }
    }, [isUpdated]);

    // const submitForm = async (props) => {
    //     try {
    //         const data = {
    //             TribeId: props.TribeId,
    //             TribeName: props.TribeName,
    //             ParentName: props.ParentName,
    //             ChildName: props.ChildName,
    //             ChildID: props.ChildID,
    //             Phone: props.Phone,
    //             Mail: props.Mail,
    //             ReasonName: "דיווח באג",
    //             LongText: props.LongText,
    //             solvedBtn: `${window.location.origin}/QuackPage?quackUrl=${props.solvedBtn}`,
    //             escalateBtn: `${window.location.origin}/QuackPage?quackUrl=${props.escalateBtn}`
    //         };
    //         const res = await contactTribe(data);
    //     } catch (error) {
    //         enqueueSnackbar(t("errorOccuredText"), { className: classes.orangeBG });
    //     }
    // }
    // handle deepLink
    const checkDeepLink = async (oHousehold, selectedActivities) => {
        // list will gather the activity from the deeplink for every children (if found)
        const activitiesToCart = [];
        // if houseHold finish to load and its not empty
        if (deepLink && Object.keys(oHousehold).length) {
            const childsOfTribe = oHousehold.ChildrenList.results.filter((child) => child.DflWhs === deepLink.tribeCode && child.PupilStatus === "Y");
            for (const child of childsOfTribe) {
                const activities = child.ChildFactoriesPortal.results;
                const activity = deepLink.unlockCode === "0"
                    ? activities.find(act => ((act.ActivCode === deepLink.activCode) && !act._id && !datePast(act.EndReg)))
                    : activities.find(act => ((act.ActivCode === deepLink.activCode) && !act._id));

                if (activity) {
                    let friendsTaxes = null;
                    if (activity.ItemPurposeType !== "2" && activity.ItemPurposeType !== "6") {
                        friendsTaxes = activities.find((act) => act.ItemPurposeType === "2" && !act._id && act.PeriodCode === activity.PeriodCode);
                        if (friendsTaxes) {
                            activitiesToCart.push(friendsTaxes);
                        }
                    }

                    if (deepLink.unlockCode === "0") {
                        const friendsTaxesInMongo = activities.find((act) => act.ItemPurposeType === "2" && act._id && act.PeriodCode === activity.PeriodCode);
                        if ((friendsTaxes && (friendsTaxes.Status_request === "4" || friendsTaxes.Status_request === "7" || activitiesToCart.find((act) => act.ItemPurposeType === "2" && act.PeriodCode === activity.PeriodCode))) || friendsTaxesInMongo.Status_request === "1") {
                            activitiesToCart.push(activity);
                        }
                    }
                    else {
                        const link = `${window.location.origin}/dl/${deepLink.tribeCode}-${deepLink.activCode}-${deepLink.classCode}-${deepLink.unlockCode}`;
                        const res = await findDeepLinkPupil.requestPromise({
                            deepLink: link,
                            pupilId: child.LicTradNum,
                            endDateFromSap: activity.EndPort
                        });
                        if (res.childAllowed) {
                            activitiesToCart.push(activity);
                        }
                    }
                    if (activity.ItemPurposeType === "6") {
                        activitiesToCart.push(activity);
                    }
                }
            }
            // if there is items to add
            if (activitiesToCart.length) { // if no collision between tribes in cart
                if (selectedActivities.length === 0 || selectedActivities[0].TribeCode === deepLink.tribeCode) {
                    addToCart(activitiesToCart);
                    setOpenActAdded(true);
                    setAddedLength(activitiesToCart.length);
                }
                // there is collision with tribes
                else {
                    openMessageBox();
                }
            }
            // no items to add
            else {
                setErrorDialog(true);
            }
            setDeepLink(null);
        }
        function addToCart() {
            activitiesToCart.forEach(activ => {
                addHouseholdSelection(activ);
            })
            addActivitiesToCart(addProduct, activitiesToCart, setHouseholdSelections, oHousehold);
            setMessageBox({ open: false });
        }
        function switchCarts() {
            selectedActivities.forEach(s => {
                removeHouseholdSelection(s);
            });
            addToCart();
            setMessageBox({ open: false });
        }
        function productsInCartDecline() {
            setMessageBox({ open: false });
        };
        function openMessageBox() {
            setMessageBox({
                open: true,
                fullWidth: false,
                maxWidth: "5rem",
                title:
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <ErrorRoundedIcon />
                            <Typography variant="h5">
                                {t("note")}
                            </Typography>
                        </Box>
                        <IconButton onClick={productsInCartDecline}>
                            <CloseIcon />
                        </IconButton>
                    </Box >,
                content:
                    <>
                        <Box display="flex" flexDirection="column">
                            <Typography component='div' variant="normalDarkGrey">{t("collisionMessage")}</Typography>
                        </Box>
                    </>,
                actions:
                    <>
                        <Styled.RoundedButton variant="contained" onClick={productsInCartDecline}>
                            {t("keepCart")}
                        </Styled.RoundedButton>
                        <Styled.RoundedButton variant="outlined"
                            onClick={switchCarts}>
                            {t("replaceCart")}
                        </Styled.RoundedButton>
                    </>
            });
            return;
        }
    };
    const copyLink = () => {
        navigator.clipboard.writeText(splitLink);
        const key = enqueueSnackbar(t('copiedLink'), { variant: "success" });
        setTimeout(() => { closeSnackbar(key) }, 1500);
    }
    const goToHistory = () => {
        setOpenSplitDialog(false);
        navigate('/parent/reghist');
    }

    return (
        <Box className={`${classes.parentBackground}`} height="100%">
            {/* Header Bar */}
            <ParentAppBar setAppBarTitle={setAppBarTitle} appBarTitle={appBarTitle} setKibbutzUser={setKibbutzUser} household={household} setCartState={setCartState} products={products} />
            {parentChildren.loading ?
                <Stack spacing={2}>
                    <Animated animationOut="fadeOut" animationOutDelay={3300} animationOutDuration={1700} isVisible={false}>
                        <Backdrop sx={{ alignItems: "center", justifyContent: "center", backgroundColor: "#3a7735b3" }} open={parentChildren.loading}>
                            <ZofimLoader />
                        </Backdrop>
                    </Animated>
                </Stack>
                : parentChildren.error ?
                    <p>{parentChildren.error}</p>
                    : ready && <>
                        <Box className={`${classes.pagesContainer}`} sx={{ top: { xs: "110px", sm: "105px", xl: "160px" } }} >
                            {protectedRoutes}
                        </Box>
                    </>}
            <TribeIsUpdatedContext.Provider value={tribeUpdatedContext}>
                <Cart removeProduct={removeHouseholdSelection} userType={kibbutzUser ? 'kibbutz' : 'parent'} isPaid={isPaid} setIsPaid={setIsPaid} isUpdated={isUpdated} setisUpdated={setIsUpdated} CardCode={household.CardCode} childrenList={household.ChildrenList?.results} />
            </TribeIsUpdatedContext.Provider>
            <MessageBox messageBoxState={messageBoxState} setMessageBox={setMessageBox} />
            <GenericDialog open={openActAdded} setOpen={setOpenActAdded} showRopes={true} height="21rem" iconSVG={approvalPay}>
                <Typography mt="1rem" sx={{ fontWeight: "bold", fontSize: { xs: "1rem", md: "1.3rem" }, textAlign: "center" }}>{`${t("added")} ${addedLength} ${t("toYourCart")}`}</Typography>
                <Typography sx={{ fontSize: "1rem", color: "#3a7735", textAlign: "center" }} >{t('toComplete')}</Typography>
            </GenericDialog >
            <GenericDialog open={errorDialog} setOpen={setErrorDialog} showRopes={true} height="23rem" iconSVG={childError}>
                <Typography mt="1rem" sx={{ fontWeight: "bold", fontSize: { xs: "1rem", md: "1.2rem" }, textAlign: "center" }}>{t("errorDialogTitle")}</Typography>
                <Typography sx={{ fontSize: "1rem", color: "#3a7735", textAlign: "center" }} >{t('errorDialogText')}</Typography>
                <Typography sx={{ fontSize: "1rem", color: "#3a7735", textAlign: "center" }} >{t('errorDialogText1')}</Typography>
                <Typography sx={{ fontSize: "1rem", color: "#3a7735", textAlign: "center" }} >{t('errorDialogText2')}</Typography>
            </GenericDialog >
            <GenericDialog open={openSplitDialog} setOpen={setOpenSplitDialog} showRopes={true} iconSVG={Splitpaymentalert}>
                {moveToHistory ?
                    <>
                        <Typography mt="1rem" sx={{ fontWeight: "bold", fontSize: { xs: "1rem", md: "1.5rem" }, textAlign: "center" }}>{t("thisTribeHaveSplit2")}</Typography>
                        <Typography sx={{ fontSize: "1rem", color: "#3a7735", textAlign: "center" }} >{t('thisTribeHaveSplit3')}</Typography>
                        <Box flexDirection='row' display='flex' justifyContent='center' alignItems='center' mt="2rem" />
                        <GreenButton onClick={e => goToHistory()}>{t('thisTribeHaveSplit4')}</GreenButton>
                    </>
                    :
                    <>
                        <Typography mt="1rem" sx={{ fontWeight: "bold", fontSize: { xs: "1rem", md: "1.5rem" }, textAlign: "center" }}>{t("thisTribeHaveSplit")}</Typography>
                        <Typography sx={{ fontSize: "1rem", color: "#3a7735", textAlign: "center" }} >{t('thisTribeHaveSplit1')}</Typography>
                        <Box flexDirection='row' display='flex' justifyContent='center' alignItems='center' className={classes.backgroundLink} mt="2rem">
                            {getSplitLinkApi.loading ? <LinearProgress /> : <Input
                                sx={{ textAlign: 'center !important' }}
                                disabled
                                className={classes.link}
                                fullWidth={true}
                                value={splitLink}
                                endAdornment={
                                    <Tooltip title={t("copy")}>
                                        <IconButton className={classes.buttons} onClick={e => copyLink()}>
                                            <ContentCopyOutlinedIcon sx={{ color: "#3a7735" }} />
                                        </IconButton>
                                    </Tooltip>
                                } />}
                        </Box>
                        <GreenButton onClick={e => window.open(splitLink, "_blank")}>{t('takeMeToPay')}</GreenButton></>}
            </GenericDialog>
            <GenericDialog open={openSummerNote} setOpen={setOpenSummerNote}>
                <Typography color="#064601" variant="mediumBoldText" textAlign="center">{t('summerNoteTitle')}</Typography>
                <Typography color="#064601" textAlign="center">{t('dearParents')}</Typography>
                <Typography color="#064601" textAlign="center">{t('summerNoteText')} </Typography>
                <Typography color="#064601" fontWeight="bold" textAlign="center">{t('summerNoteText0')} </Typography>
                <Typography color="#064601" textAlign="center">{t('summerNoteText1')} </Typography>
                <Typography color="#064601" mb="3rem" textAlign="center">{t('summerNoteText2')} </Typography>
                <Button onClick={e => setOpenSummerNote(false)}>{t('close')}</Button>
            </GenericDialog>
        </Box >
    );
}
export default Parent;