//hooks
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useApi from "hooks/useApi";
import useGlobal from "../store/store";
// Mui
import { Box, Typography, Select, MenuItem, Button, CircularProgress } from "@mui/material";
import { styled } from '@mui/system';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

//api
import { getTribePupils, searchInParent } from 'api/api';
//components
import { MyInputStyle } from "Components/TribeComponents/OpenSignReqs";
import { CustomBusy } from "Components/Common/CustomBusy";
import PupilsTable from "Components/TribeComponents/PupilsTable";
import FiltersDialog from "Components/TribeComponents/FiltersDialog";
//data
import { permanentInfo } from 'data/permanentInfo';
//assets
import inactiveChildren from '../assets/inactiveChildren.svg'

const GreenButton = styled(Button)(({ ...props }) => ({
    "&:hover": {
        background: '#cee2cd !important'
    },
    backgroundColor: props.disabled ? '#dbdbdb !important' : '#3a7735 !important',
    color: props.disabled ? '#a2a2a2 !important' : "#ffffff !important",
    alignItems: 'center !important',
    justifyContent: 'center !important',
    width: '14.7rem !important',
    fontSize: '1.2rem !important',
    fontWeight: "bold !important",
    borderRadius: '50px !important',
    margin: "2rem 5rem !important",
    textTransform: "capitalize !important"
}));

const PupilsUpdatePage = ({ tribe, familyUpdated }) => {
    const { t } = useTranslation();
    const getPupilsByTribe = useApi(getTribePupils);
    const search = useApi(searchInParent);
    const [sorter, setSorter] = useState(1);
    const [pupilsResultCount, setPupilsResultCount] = useState(0);
    const [pupilsList, setPupilsList] = useState([]);
    const [pupilsListGrade, setPupilsListGrade] = useState([]);
    const [selected, setSelected] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [classNo, setClassNo] = useState('');
    const [troopFilter, setTroopFilter] = useState('');
    const [troopsList, setTroopsList] = useState([]);
    const [classNumber, setClassNumber] = useState('');
    const [filterObj, setFilterObj] = useState({});
    const [applyFilter, setApplyFilter] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);
    const [family, setFamily] = useGlobal((state) => state.family, (actions) => actions.setFamily);
    const [child, setChild] = useGlobal(state => state.child, actions => actions.setChild);
    const [updatePupilDrawer, setUpdatePupilDrawer] = useGlobal((state) => state.updatePupilDrawer, (actions) => actions.setUpdatePupilDrawer);
    const [troopClass, setTroopClass] = useGlobal((state) => state.troopClass, (actions) => actions.setTroopClass);
    const [pupilsToUpdate, setPupilsToUpdate] = useGlobal((state) => state.pupilsToUpdate, (actions) => actions.setPupilsToUpdate);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        let isMounted = true;
        isMounted && pupilsListGrade.length && setReady(true);
        return () => { isMounted = false }
    }, [pupilsListGrade]);

    useEffect(() => {
        setSelected([]);
        setClassNo('');
        setClassNumber('');
        setTroopFilter('');
        getPupils();
    }, [tribe, familyUpdated])

    useEffect(() => {
        setReady(false);
        setSelected([]);
        setTroopFilter('');
        const classNum = Object.keys(permanentInfo.childClasses).find(key => permanentInfo.childClasses[key] === classNo);
        const index = pupilsList.findIndex((lis) => lis.classNumber === classNum);
        setClassNumber(classNum);
        if (index >= 0) {
            setPupilsListGrade([pupilsList[index]]);
            const troopsListArray = makeTroopList(pupilsList[index], index, true);
            setTroopsList(troopsListArray);
            setReady(false);
        } else {
            const noData = { classNumber: classNum, pupils: [] };
            setPupilsListGrade([noData]);
            setTroopsList([]);
        }
    }, [classNo])


    const getPupils = async () => {
        const query = { tribeCode: tribe?.UnitCode, classNo: '00' };
        const pupilsResult = await getPupilsByTribe.requestPromise(query);
        setPupilsResultCount(pupilsResult.totalPupils);
        setPupilsList(pupilsResult.pupilList);
        const tempClass = classNo ? classNo : permanentInfo.childClasses[pupilsResult.pupilList[0]?.classNumber];
        setClassNo(tempClass);
        const index = pupilsResult.pupilList.findIndex((lis) => lis.classNumber === classNumber);
        setClassNumber(index >= 0 ? pupilsResult.pupilList[index]?.classNumber : pupilsResult.pupilList[0]?.classNumber);
        setPupilsListGrade([index >= 0 ? pupilsResult.pupilList[index] : pupilsResult.pupilList[0]]);
        const troopsListArray = makeTroopList(pupilsResult, index);
        setTroopsList(troopsListArray);
        setClearFilter(false);
    }
    const makeTroopList = (pupilsResult, index, fromClass = false) => {

        const uniqueCombinations = new Set();
        const troopsListArray = [];
        const temp = fromClass ? pupilsResult.pupils : pupilsResult.pupilList[index >= 0 ? index : 0].pupils;
        temp.forEach(result => {
            const troops = result.Troops;
            const groups = result.Groups;
            const key = `${troops}-${groups}`; // Create a unique key based on Troops-Groups

            if (troops && groups && !uniqueCombinations.has(key)) {
                uniqueCombinations.add(key);
                troopsListArray.push({ filterTroop: `${troops} - ${groups}` });
            }
        });
        troopsListArray.push({ filterTroop: 'ללא שיוך' });
        return troopsListArray;
    }

    useEffect(() => {
        if (clearFilter) {
            getPupils();
        }
    }, [clearFilter]);

    useEffect(() => {
        makeFilters();
    }, [searchValue, applyFilter, filterObj]);

    const makeFilters = () => {
        let filteredPupils = structuredClone(pupilsListGrade);

        // Handle class filtering
        if (Boolean(!searchValue && classNo && !applyFilter)) {
            const classNum = Object.keys(permanentInfo.childClasses).find(key => permanentInfo.childClasses[key] === classNo);
            const index = pupilsList.findIndex((lis) => lis.classNumber === classNum);
            filteredPupils = index >= 0 ? [pupilsList[index]] : [pupilsList[0]];
        } else {
            // Handle search filtering
            if (searchValue.length >= 3) {
                filteredPupils[0].pupils = filteredPupils[0].pupils.filter(child =>
                    (child.Pupil_FirstName.concat(' ', child.Pupil_LastName)).includes(searchValue) ||
                    child.Pupil_FirstName.includes(searchValue) ||
                    child.Pupil_LastName.includes(searchValue) ||
                    child.PupilID.includes(searchValue));
            }

            // Handle additional filters
            if (applyFilter && (filterObj.selectedGroup || filterObj.selectedTroop || filterObj.selectedSchool || filterObj.currentFilterTroop)) {
                if (filterObj.currentFilterTroop === "ללא שיוך") {
                    filteredPupils[0].pupils = filteredPupils[0].pupils.filter((ch) => {
                        return (
                            (`${ch.Troops} - ${ch.Groups}` === ` - ` || `${ch.Troops} - ${ch.Groups}` === `null - null`)
                        );
                    });
                } else {
                    filteredPupils[0].pupils = filteredPupils[0].pupils.filter((ch) => {
                        return (
                            (!filterObj.selectedGroup || ch.Groups === filterObj.selectedGroup) &&
                            (!filterObj.selectedTroop || ch.Troops === filterObj.selectedTroop) &&
                            (!filterObj.selectedSchool || ch.School === filterObj.selectedSchool) &&
                            (!filterObj.currentFilterTroop || `${ch.Troops} - ${ch.Groups}` === filterObj.currentFilterTroop)
                        );
                    });
                }
            }
        }
        setPupilsListGrade(filteredPupils);
    }

    const handleOpenDrawer = async () => {
        if (selected.length === 1) {
            const data = await search.requestPromise(tribe?.UnitCode, selected[0].PupilID);
            setFamily(data.d.results[0]);
            setChild(data.d.results[0].ChildrenList.results.find(ch => ch.LicTradNum === selected[0].PupilID));
            setUpdatePupilDrawer({ isOpen: true, level: 1 });
        }
        else {
            setTroopClass(selected[0].Class);
            setPupilsToUpdate(selected);
            setUpdatePupilDrawer({ isOpen: true, level: 2 });
        }
    }

    return (
        <>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="row" >
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" marginLeft="2rem">
                        <EventAvailableIcon sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' } }} />
                        <Typography marginLeft="0.5rem" variant="mediumDarkGreyBoldRes">{`${t("pupilTribe")} ${tribe.UnitName} (${pupilsResultCount})`} </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" marginLeft="2rem">
                        <GreenButton disabled={!selected.length} onClick={e => handleOpenDrawer()}>
                            {search.loading ? <CircularProgress sx={{ color: "#fff" }} size={32} /> : `${selected.length > 1 ? t("updatePupils") : t("updatePupil")} (${selected.length})`}
                        </GreenButton>
                    </Box>
                </Box>
                <SortLine
                    setSorter={setSorter}
                    setSearchValue={setSearchValue}
                    tribeCode={tribe?.UnitCode}
                    filterObj={filterObj}
                    setFilterObj={setFilterObj}
                    setApplyFilter={setApplyFilter}
                    setClearFilter={setClearFilter}
                    classNo={classNo}
                    setClassNo={setClassNo}
                    troopsList={troopsList}
                    troopFilter={troopFilter}
                    setTroopFilter={setTroopFilter}

                />
                {getPupilsByTribe.loading || !pupilsListGrade || !ready ? <CustomBusy text={t("willTakeFewMoments")} /> :
                    <PupilsTable
                        rows={pupilsListGrade}
                        sorter={sorter}
                        selected={selected}
                        setSelected={setSelected}
                    />}

            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }} flexDirection='column' alignItems='center'>
                <img
                    alt={"..."}
                    src={inactiveChildren}
                    width="150px"
                    height="150px"
                />
                <Typography sx={{ color: "#ff0000", fontSize: "1rem", fontWeight: "bold" }} >{t("tableNotActive")}</Typography>
            </Box>
        </>
    );
}
const SortLine = ({ setSorter, setSearchValue, tribeCode, filterObj, setFilterObj, setApplyFilter, setClearFilter, classNo, setClassNo, troopsList, troopFilter, setTroopFilter }) => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(1); // Set initial value to the code of the default option
    const [openFilters, setOpenFilters] = useState(false);
    const options = [
        { label: t("pupilName"), code: 1 },
        { label: t("troopGroup"), code: 2 }
    ];

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        setSorter(selectedValue);
    };
    const openFiltersDialog = () => {
        setTroopFilter('');
        setApplyFilter(false);
        setOpenFilters(true);
    }
    const handleCureentFilter = (value) => {
        setApplyFilter(true);
        setTroopFilter(value);
        setFilterObj({
            ...filterObj, currentFilterTroop: value
        });
    }

    const handleClearCurrentFilter = () => {
        setApplyFilter(false);
        setTroopFilter('');
    }
    return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" marginLeft="1rem">
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" marginRight="2rem">
                <MyInputStyle
                    variant="outlined"
                    placeholder={t('searchPupil')}
                    disabled={!classNo}
                    onChange={e => setSearchValue(e.target.value)}
                    InputProps={{
                        endAdornment: (<SearchTwoToneIcon sx={{ color: '#3a7735' }} />)
                    }} />
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" marginRight="2rem">
                    <Typography>{t("chooseClass")}</Typography>
                    <Select
                        sx={{ paddingLeft: "1rem" }}
                        variant='standard'
                        value={classNo}
                        disabled={!classNo}
                        onChange={e => setClassNo(e.target.value)}
                    >
                        {Object.values(permanentInfo.childClasses).map((childClass, i) => {
                            return (
                                <MenuItem value={childClass} key={i}>{childClass}</MenuItem>
                            )
                        })}
                    </Select>
                </Box>

                <Button onClick={e => openFiltersDialog()} disabled={!classNo} endIcon={<FilterAltIcon sx={{ color: '#3a7735' }} />} sx={{ fontSize: "1.1rem", fontWeight: "bold", ':hover': { backgroundColor: "transparent" } }} >{t("filters")}</Button>
                <FiltersDialog
                    open={openFilters}
                    setOpen={setOpenFilters}
                    tribeCode={tribeCode}
                    filterObj={filterObj}
                    setFilterObj={setFilterObj}
                    setApplyFilter={setApplyFilter}
                    setClearFilter={setClearFilter}
                    classNo={classNo}
                    troopsList={troopsList}
                    troopFilter={troopFilter}
                    handleCureentFilter={handleCureentFilter}
                    handleClearCurrentFilter={handleClearCurrentFilter}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" marginRight="2rem">
                <Typography>{t("sortTableBy")}</Typography>
                <Select
                    sx={{ paddingLeft: "1rem" }}
                    variant='standard'
                    value={selectedOption}
                    disabled={!classNo}
                    onChange={handleChange}>
                    {options.map((option, i) => (
                        <MenuItem key={i} value={option.code}>{option.label}</MenuItem>
                    ))}
                </Select>
            </Box>
        </Box>
    );
};
export default PupilsUpdatePage;